#location {
    background-color: rgb(200, 224, 178);
    margin: 50px 0px;
}

#location-section {
    padding-left: 130px;
}

#location-box {
    border: 1px solid black;
    padding: 5px;
    display: inline-block;
}

#location-box:hover {
    background-color: rgba(179, 202, 158, 0.824);
}

#location-image-container {
    width: 100%;
    background-image:linear-gradient(to right, #C9E1B4, rgba(255, 255, 255, 0)), url(../img/location-img.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
}

.location-button {
    font-size: 18px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@media (max-width: 800px) { 
    #location-section {
        padding-left: 0px;
    }

    #location-image-container{
        display: none;
    }

    #location {
        background: linear-gradient(rgba(179, 202, 158, 0.824), #C9E1B4), url(../img/location-img.jpeg) no-repeat center center / cover;
    }

    .location-button {
        font-size: 15px;
    }
}