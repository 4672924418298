#banner {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 700px;    
    max-height: 700px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/banner.jpeg);
    padding: 200px;

    background-attachment: fixed;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

#banner h1 {
    color: white;
    font-weight: 400;
    letter-spacing: 5px;
    font-size: 64px;
}

#banner button {
    padding: 20 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    font-size: 25px;
}

@media (max-width: 800px) { 
    #banner{
        min-height: 400px;
        max-height: 400px;
        padding: 50px;
        background-attachment: none;
        background-position: top;
        background-repeat: no-repeat;
        background-size: auto;
    }
    #banner h1{
        width: 100%;
        font-size: 30px;
    }
    #banner button {
        font-size: 20px;
    }
}