#navbar {
    background-color: white;
    position: sticky;
}

#logo {
    width: 200px;
}

#navbar .nav-link{
    font-size: 20px;
    padding: 0px 20px 20px 20px;
}

@media (max-width: 800px) { 
    #logo {
        width: 150px;
    }
    #navbar .nav-link{
        font-size: 15px;
        padding: 20px 10px 10px 20px;
    }
}