#menu {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu-background {
    position: relative;
    min-width: 450px;
    min-height: 250px;
    margin: 0px 40px;
    background-size: cover;
    background-position: center; 
    cursor: pointer;
    overflow: hidden; 
}

#menu1::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../img/Menu_1.jpg');
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease-in-out;
    z-index: 0; 
}

.menu-background:hover::before {
    transform: scale(1.3);
}

#menu2::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../img/Menu_2.jpg');
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease-in-out;
    z-index: 0; 
}

#menu3::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../img/Menu_3.jpg');
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease-in-out;
    z-index: 0; 
}

#menu4::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../img/Menu_4.jpg');
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease-in-out;
    z-index: 0; 
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    color: white; 
    font-weight: 400;
    font-size: 30px; 
    letter-spacing: 2px;
  }

#menu .menu-img {
    width: 450px;
    margin: 0px 30px;
}

#menu .menu-img:hover {
    filter: brightness(90%);
}

.menu-model-img {
    width: 100%;
}

@media (max-width: 800px) { 
    #menu .menu-img {
        width: 300px;
    }

    .menu-background{
        margin-top: 20px !important;
    }
}