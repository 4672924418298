html {
  scroll-padding-top: 180px;
}

#app {
  font-family: 'Poppins';
}

.sec-color {
  background-color: #C9E1B4; 
  border: 0;
  color: black;
  padding: 0.7rem 1.5rem;
  border-radius: 1rem;
  font-size: 1.3rem;
  line-height: 1.5;
}

.pri-color {
  background-color: #78B443; 
  border: 0;
  color: white;
  padding: 0.7rem 1.5rem;
  border-radius: 1rem;
  font-size: 1.3rem;
  line-height: 1.5;
}

.title { 
  text-align: center;
  color: #78B443;  
  letter-spacing: 5px;
  font-size: 40px;
}

.leaf {
  position: absolute;
  width: 125px; /* Adjust size as needed */
  height: 125px; /* Adjust size as needed */
  background-size: contain;
  background-repeat: no-repeat;
}

.leaf-top-right {
  /* top: 0; */
  right: 0;
  background-image: url('img/leaf-right.png');
}

.leaf-bottom-left {
  left: 0;
  background-image: url('img/leaf-left.png');
}

@media (max-width: 800px) { 
  .sec-color {
    font-size: 1.0rem;
  }
  .leaf {
    display: none;
  }
}