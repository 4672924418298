#about {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
}

#history-summary {
    line-height: 1.8em;
}

#about-img {
    width: 100%;
}

#about-img-text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

#about-img-box {
    width: 85%;
}

#about-img-container {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}

.gallery-img {
    width: 15em;
    height: 15em;
}

.gallery-container {
    padding: 5px;
}

@media (max-width: 800px) {
    #about-img-container {
        justify-content: center;
        width: 100%;
    }

    .gallery-img {
        width: 10em;
        height: 10em;
    }

    .gone {
        display: none;
    }
}